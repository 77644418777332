import cloudsBanner from './backgrounds/clouds-banner.png'
import spaceOwl from './backgrounds/spaceowl.svg'
import ccmIllustration from './cloud-cost-management/ccm_v1.png'
import componentsEmpty from './components/empty.png'
import environmentsEmpty from './environments/empty.png'
import error500 from './errors/500_small.png'
import other from './errors/other_small.png'
import zeroState from './infra-import/zero-state.png'
import davidbowlie from './owls/davidbowlie.svg'
import deadpowl from './owls/deadpowl.svg'
import dowlek from './owls/dowlek.svg'
import emmetbrowl from './owls/emmetbrowl.svg'
import gandowlf from './owls/gandowlf.svg'
import hypnoowl from './owls/hypnoowl.svg'
import jacksparrowl from './owls/jacksparrowl.svg'
import catalogRepositories from './presentation/catalog-repositories.svg'
import configRepository from './presentation/config-repository.gif'
import monitoring from './presentation/monitoring.png'
import more from './presentation/more.svg'
import organizations from './presentation/organizations.svg'
import pipelines from './presentation/pipelines.gif'
import privateCatalogs from './presentation/private-catalogs.gif'
import projects from './presentation/projects.gif'
import stacks from './presentation/stacks.png'

export const owls = {
  davidbowlie,
  deadpowl,
  dowlek,
  emmetbrowl,
  gandowlf,
  hypnoowl,
  jacksparrowl,
}

export const presentation = {
  organizations,
  more,
  catalogRepositories,
  configRepository,
  privateCatalogs,
  pipelines,
  projects,
  stacks,
  monitoring,
}

export const infraImport = {
  zeroState,
}

export const ccm = {
  illustration: ccmIllustration,
}

export const components = {
  empty: componentsEmpty,
}

export const environments = {
  empty: environmentsEmpty,
}

export const backgrounds = {
  cloudsBanner,
  spaceOwl,
}

export const errored = {
  error500,
  other,
}

export default {
  backgrounds,
  ccm,
  infraImport,
  owls,
  presentation,
}
